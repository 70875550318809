import "../../style.scss"

import { Popover } from "@amzn/awsui-components-react"
import Link from "@amzn/awsui-components-react/polaris/link"
import { TableProps } from "@amzn/awsui-components-react/polaris/table"
import { CustomerOpportunityResponse } from "api/customerOpportunities"
import NavigationLink from "pmsa-polaris/components/NavigationLink"
import config from "pmsa-polaris/config"
import { routeParams } from "routes"
const { sfdcBaseUrl } = config

import { VISIBLE_COLUMN_OPTIONS } from "./columnsNames"

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<CustomerOpportunityResponse>[] = [
  {
    id: "id",
    cell: (item) => (
      <Popover
        header={item.name}
        size="large"
        triggerType="text"
        content={
          <div>
            <h4 style={{ margin: "5px" }}>Description </h4>
            <p style={{ margin: "5px" }}>{item.description}</p>
            <h4 style={{ margin: "5px" }}>Next Steps </h4>
            <p style={{ margin: "5px" }}>
              {item.nextStep ? (item.nextStep.length > 128 ? item.nextStep.slice(0, 128).concat("...") : item.nextStep) : "No Next Step"}
            </p>
          </div>
        }
        renderWithPortal={true}
        dismissAriaLabel="Close"
      >
        {item.id}
      </Popover>
    ),
    header: "Opportunity ID",
    sortingField: "id",
  },
  {
    id: "opportunityName",
    cell: (item) => <NavigationLink href={routeParams.customerOpportunityDetails({ id: item.id })}>{item.name}</NavigationLink>,
    header: "Opportunity",
    sortingField: "name",
  },
  {
    id: "partnerName",
    header: "Partner",
    cell: (item) => (
      <Link href={`https://discover.awspartner.com/?kw=${item.accountName}&type=allPartners`} target="_blank" external>
        {item.partnerAccountName}
      </Link>
    ),
    sortingField: "partnerName",
  },
  {
    id: "apnId",
    header: "APN Id",
    cell: (item) => item.apnId,
    sortingField: "apnId",
  },
  {
    id: "accountName",
    header: "Customer",
    cell: (item) => (
      <Link href={`${sfdcBaseUrl}/lightning/r/Account/${item.accountId}/view`} target="_blank" external>
        {item.accountName}
      </Link>
    ),
    sortingField: "accountName",
  },
  {
    id: "stageName",
    header: "Stage",
    cell: (item) => item.stageName,
    width: 125,
    sortingField: "stageName",
  },
  {
    id: "opportunityAmount",
    header: "Value",
    cell: (item) => new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(parseFloat(item?.opportunityAmount || "0")),
    sortingField: "opportunityAmount",
  },
  {
    id: "fiscalYear",
    header: "Fiscal Year",
    cell: (item) => item.fiscalYear,
    sortingField: "fiscalYear",
  },
  {
    id: "createdDate",
    header: "Created Date",
    cell: (item) => item.createdDate.substring(0, 10),
    sortingField: "createdDate",
  },
  {
    id: "lastModifiedDate",
    header: "Last Modified Date",
    cell: (item) => item.lastModifiedDate.substring(0, 10),
    sortingField: "lastModifiedDate",
  },
  {
    id: "closeDate",
    header: "Close Date",
    cell: (item) => item.closeDate,
    width: 130,
    sortingField: "closeDate",
  },
  {
    id: "closedLostCategory",
    header: "Closed Lost Category",
    cell: (item) => item.closedLostCategory,
    sortingField: "closedLostCategory",
  },
  {
    id: "closedLostReasonNotes",
    header: "Closed Lost Reason Notes",
    cell: (item) => item.closedLostReasonNotes,
    sortingField: "closedLostReasonNotes",
  },
  {
    id: "nextStep",
    header: "Next Step",
    cell: (item) => item.nextStep,
    sortingField: "nextStep",
  },
  {
    id: "description",
    header: "Description",
    cell: (item) => item.description,
    sortingField: "description",
  },
  {
    id: "tasks",
    header: "Tasks (#)",
    cell: (item) => item.tasks.length,
    sortingField: "tasks",
  },
]

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Visible columns",
    options: VISIBLE_COLUMN_OPTIONS,
  },
]

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 Opportunities" },
  { value: 30, label: "30 Opportunities" },
  { value: 50, label: "50 Opportunities" },
]
