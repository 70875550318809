import { TopNavigation } from "@amzn/awsui-components-react"
import { Auth } from "aws-amplify"
import logo from "logo.png"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import routes from "../routes"

const TopNavigationBar: React.FC = () => {
  const navigate = useNavigate()

  const [user, setUser] = useState({
    name: "",
    email: "",
  })

  useEffect(() => {
    try {
      Auth.currentAuthenticatedUser({
        bypassCache: false,
      }).then((user) => {
        setUser({
          name: `${user.attributes.given_name} ${user.attributes.family_name}`,
          email: user.attributes.email,
        })
      })
    } catch (e) {
      //setError(e);
    }
  }, [])

  return (
    <TopNavigation
      i18nStrings={{
        overflowMenuTriggerText: "More",
        overflowMenuTitleText: "More",
      }}
      identity={{
        href: "/#",
        title: "Project Thunder",
        logo: {
          src: logo,
          alt: "Thunder",
        },
      }}
      utilities={[
        {
          type: "button",
          iconName: "settings",
          title: "Settings",
          onClick: (e) => {
            e.preventDefault()
            navigate(`${routes.profile}`)
          },
        },
        {
          type: "menu-dropdown",
          text: user.name,
          description: user.email,
          iconName: "user-profile",
          onItemClick: (e) => {
            if (e.detail.href) {
              e.preventDefault()
              if (e.detail.external) window.open(e.detail.href, "_blank", "noopener,noreferrer")
              else navigate(e.detail.href)
            }
          },
          items: [
            {
              id: "ticket-links",
              text: "My Profile",
              items: [
                {
                  id: "profile",
                  text: "Profile",
                  href: `${routes.profile}`,
                },
              ],
            },
            {
              id: "ticket-links",
              text: "Tickets",
              items: [
                {
                  id: "bug",
                  text: "Submit a bug",
                  href: "https://t.corp.amazon.com/create/templates/2a548f26-f8d3-4c12-af34-913405179c2a",
                  external: true,
                  externalIconAriaLabel: " (opens in new tab)",
                },
                {
                  id: "sfdc",
                  text: "Submit a feature",
                  href: "https://t.corp.amazon.com/create/templates/cd8620ff-1754-48c0-bf3a-3f05b79f1f18",
                  external: true,
                  externalIconAriaLabel: " (opens in new tab)",
                },
              ],
            },
            {
              id: "support-group",
              text: "Links",
              items: [
                {
                  id: "thunder",
                  text: "Thunder Wiki",
                  href: "https://w.amazon.com/bin/view/AWS/Teams/PartnerSA/Thunder/",
                  external: true,
                  externalIconAriaLabel: " (opens in new tab)",
                },
                {
                  id: "releaseNotes",
                  text: "Release Notes",
                  href: "https://w.amazon.com/bin/view/AWS/Teams/PartnerSA/Thunder/ReleaseNotes/",
                  external: true,
                  externalIconAriaLabel: " (opens in new tab)",
                },
                {
                  id: "quicksight",
                  text: "APO Tech IC Dashboard",
                  href: "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/a2deaf8b-30d8-424b-b410-4ab38ec4660a?directory_alias=amazonbi&ignore=true#",
                  external: true,
                  externalIconAriaLabel: " (opens in new tab)",
                },
                {
                  id: "workstreams",
                  text: "Workstreams Catalogue",
                  href: "https://w.amazon.com/bin/view/AWS/Teams/PartnerSA/Partner_Management/Workstreams/",
                  external: true,
                  externalIconAriaLabel: " (opens in new tab)",
                },
                {
                  id: "sfdc",
                  text: "AWS SFDC",
                  href: "https://aws-crm.my.salesforce.com/",
                  external: true,
                  externalIconAriaLabel: " (opens in new tab)",
                },
              ],
            },
          ],
        },
      ]}
    />
  )
}

export default TopNavigationBar
