const SA_ACTIVITY_MAPPING = {
  "PTR - Technical Validations": [
    {
      activityName: "Beta Demo", // in activity list
      saActivity: "Beta Demo [Architecture]", // SA Activity field SFDC, can be blank
      code: "TV-11", // workstream code
    },
    {
      activityName: "Beta Prototype/PoC Support/PFR",
      saActivity: "Beta Prototype/PoC Support/PFR [Architecture]",
      code: "TV-12",
    },
    {
      activityName: "Cloud Adoption Framework",
      saActivity: "Cloud Adoption Framework [Architecture]",
      code: "TV-06",
    },
    {
      activityName: "Co-sell",
      saActivity: "Co-sell[Management]",
      code: "TV-17",
    },
    {
      activityName: "Cost Optimization",
      saActivity: "Cost Optimization [Cost]",
      code: "TV-14",
    },
    {
      activityName: "Customer Architecture Review",
      saActivity: "Customer Architecture Review [Architecture]",
      code: "TV-04",
    },
    {
      activityName: "Customer Demo",
      saActivity: "Customer Demo [Architecture]",
      code: "TV-07",
    },
    {
      activityName: "Customer Prototype/PoC Support",
      saActivity: "Customer Prototype/PoC Support [Architecture]",
      code: "TV-09",
    },
    {
      activityName: "General Architecture Review",
      saActivity: "Solution Architecture Review [Architecture]",
      code: "OM-02",
    },
    {
      activityName: "Migration Acceleration Program Support",
      saActivity: "Migration Acceleration Program Support [Management]",
      code: "TV-13",
    },
    {
      activityName: "Migration Readiness Assessment",
      saActivity: "Migration Readiness Assessment [Architecture]",
      code: "TV-08",
    },
    {
      activityName: "PMSA Conducts a Well-Architected Review",
      saActivity: "Well Architected [Architecture]",
      code: "WA-05",
    },
    {
      activityName: "RFI and RFP response",
      saActivity: "RFI and RFP response [Workshops]",
      code: "TV-15",
    },
    {
      activityName: "Solution Architecture Review",
      saActivity: "Solution Architecture Review [Architecture]",
      code: "TV-01",
    },
    {
      activityName: "Solution Demo",
      saActivity: "Solution Demo [Architecture]",
      code: "TV-02",
    },
    {
      activityName: "Solution Prototype/PoC Support",
      saActivity: "Solution Prototype/PoC Support [Architecture]",
      code: "TV-03",
    },
    {
      activityName: "Statement of Work",
      saActivity: "Statement of Work [SoW]",
      code: "TV-16",
    },
    {
      activityName: "Technical Discovery Review",
      saActivity: "Technical Discovery Review [Architecture]",
      code: "TV-05",
    },
    {
      activityName: "Well Architected",
      saActivity: "Well Architected [Architecture]",
      code: "TV-10",
    },
  ],
  "PTR - Partner Programs Technical Validations": [
    {
      activityName: "Competency",
      saActivity: "Competency [Architecture]",
      code: "PTV-01",
    },
    {
      activityName: "Foundational Technical Review",
      saActivity: "Foundational Technical Review [Architecture]",
      code: "PTV-02",
    },
    {
      activityName: "Service Delivery/ Service Ready",
      saActivity: "Service Delivery/ Service Ready [Architecture]",
      code: "PTV-03",
    },
  ],
  "PTR - Public Speaking": [
    {
      activityName: "Immersion Day Delivered by PMSA",
      saActivity: "Other Thought Leadership [Thought Leadership]",
      code: "ID-05",
    },
    {
      activityName: "Other Public speaking",
      saActivity: "Other Public speaking [Thought Leadership]",
      code: "PS-03",
    },
    {
      activityName: "Public Speaking AWS Conference",
      saActivity: "Public Speaking AWS Conference [Thought Leadership]",
      code: "PS-01",
    },
    {
      activityName: "Public Speaking Partner Conference",
      saActivity: "Public Speaking Partner Conference [Thought Leadership]",
      code: "PS-02",
    },
    {
      activityName: "Webinars",
      saActivity: "Webinars [Workshops]",
      code: "PS-04",
    },
  ],
  "PTR - Strategic Enablement": [
    {
      activityName: "AWSome Day",
      saActivity: "AWSome Day [Workshops]",
      code: "SE-03",
    },
    {
      activityName: "Game Day",
      saActivity: "Game Day [Workshops]",
      code: "SE-02",
    },
    {
      activityName: "Hackathon",
      saActivity: "Hackathon [Workshops]",
      code: "SE-04",
    },
    {
      activityName: "Immersion Day General",
      saActivity: "Immersion Day General [Workshops]",
      code: "SE-01",
    },
    {
      activityName: "Other Workshops",
      saActivity: "Other Workshops [Workshops]",
      code: "SE-05",
    },
    {
      activityName: "Webinars",
      saActivity: "Webinars [Workshops]",
      code: "PS-04",
    },
  ],
  "PTR - External Technical Content": [
    {
      activityName: "AOD/SME",
      saActivity: "AOD/SME [Thought Leadership]",
      code: "ETC-04",
    },
    {
      activityName: "Blog",
      saActivity: "Blog [Thought Leadership]",
      code: "ETC-02",
    },
    {
      activityName: "General Tech Content",
      saActivity: "General Tech Content [Thought Leadership]",
      code: "ETC-03",
    },
    {
      activityName: "Other Partner Content",
      saActivity: "Other Partner Content [Thought Leadership]",
      code: "ETC-07",
    },
    {
      activityName: "Other Thought Leadership",
      saActivity: "Other Thought Leadership [Thought Leadership]",
      code: "ETC-06",
    },
    {
      activityName: "Partner Offering Build Sales Kit",
      saActivity: "",
      code: "PO-05",
    },
    {
      activityName: "Partner Offering Build Technical Delivery Kit",
      saActivity: "",
      code: "PO-04",
    },
    {
      activityName: "POV",
      saActivity: "POV [Thought Leadership]",
      code: "ETC-08",
    },
    {
      activityName: "PR FAQ",
      saActivity: "PR FAQ [Thought Leadership]",
      code: "ETC-09",
    },
    {
      activityName: "Tutorial videos",
      saActivity: "Tutorial videos [Thought Leadership]",
      code: "ETC-05",
    },
    {
      activityName: "White Paper",
      saActivity: "White Paper [Thought Leadership]",
      code: "ETC-01",
    },
    {
      activityName: "Content Security Review (CSR)",
      saActivity: "Content Security Review (CSR) [Thought Leadership]",
      code: "ETC-10",
    },
  ],
  "PTR - Internal Technical Content": [
    {
      activityName: "Other Partner Content",
      saActivity: "Other Partner Content [Thought Leadership]",
      code: "ETC-07",
    },
    {
      activityName: "POV",
      saActivity: "POV [Thought Leadership]",
      code: "ETC-08",
    },
    {
      activityName: "PR FAQ ",
      saActivity: "PR FAQ [Thought Leadership] ",
      code: "ETC-09",
    },
  ],
  "PTR - Miscellaneous": [
    {
      activityName: "ACE Integration",
      saActivity: "",
      code: "OE-02",
    },
    {
      activityName: "Assess Partner Financials",
      saActivity: "",
      code: "FP-00",
    },
    {
      activityName: "Assess Partner Maturity",
      saActivity: "",
      code: "PM-03",
    },
    {
      activityName: "AWS Ambassador Program Support",
      saActivity: "",
      code: "AMB-20",
    },
    {
      activityName: "Build CCoE",
      saActivity: "",
      code: "PB-01",
    },
    {
      activityName: "Business Review Planning and Preparation",
      saActivity: "",
      code: "PM-02",
    },
    {
      activityName: "Catalyst Initiative Participation",
      saActivity: "",
      code: "PB-02",
    },
    {
      activityName: "Conduct APN Differentiation Program",
      saActivity: "",
      code: "FP-03",
    },
    {
      activityName: "Conduct CCoE Planning",
      saActivity: "",
      code: "FP-04",
    },
    {
      activityName: "Conduct Operational Excellence Planning",
      saActivity: "",
      code: "FP-06",
    },
    {
      activityName: "Conduct Partner Enablement Planning",
      saActivity: "",
      code: "FP-05",
    },
    {
      activityName: "Conduct Partner Offer Planning",
      saActivity: "",
      code: "FP-02",
    },
    {
      activityName: "Conduct Working Backwards Workshop",
      saActivity: "",
      code: "FP-01",
    },
    {
      activityName: "Coordinate AWS Learning Path",
      saActivity: "",
      code: "PE-02",
    },
    {
      activityName: "Coordinate Certification Preparation",
      saActivity: "",
      code: "PE-03",
    },
    {
      activityName: "Coordinate STP Training",
      saActivity: "",
      code: "PE-01",
    },
    {
      activityName: "Demand Gen Support",
      saActivity: "",
      code: "DG-01",
    },
    {
      activityName: "Foundational Overview Talk",
      saActivity: "",
      code: "PE-12",
    },
    {
      activityName: "Improve Operational Excellence",
      saActivity: "",
      code: "OE-01",
    },
    {
      activityName: "Introduce APN Navigate Program",
      saActivity: "",
      code: "PE-04",
    },
    {
      activityName: "Partner ID Capability Building",
      saActivity: "",
      code: "ID-03",
    },
    {
      activityName: "Partner Offering Ideation",
      saActivity: "",
      code: "PO-02",
    },
    {
      activityName: "Partner Positioning for Area",
      saActivity: "",
      code: "PM-01",
    },
    {
      activityName: "Partner WA Capability Building",
      saActivity: "",
      code: "WA-03",
    },
    {
      activityName: "PQM Resolution",
      saActivity: "",
      code: "QM-01",
    },
    {
      activityName: "Proof Of Concept Validation",
      saActivity: "",
      code: "OM-01",
    },
    {
      activityName: "re:Stack Project Planning",
      saActivity: "",
      code: "RS-01",
    },
    {
      activityName: "Review AWS Ambassador Content",
      saActivity: "",
      code: "AMB-03",
    },
    {
      activityName: "SA Connection / Office Hours",
      saActivity: "",
      code: "PE-06",
    },
  ],
}

export default SA_ACTIVITY_MAPPING
