import { SideNavigation } from "@amzn/awsui-components-react"
import config from "pmsa-polaris/config"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import routes from "../routes"

const SideNavigationBar: React.FC = () => {
  const navigate = useNavigate()
  const { thunderYear } = config

  const [activeHref, setActiveHref] = useState(useLocation().pathname)

  return (
    <SideNavigation
      onFollow={(e) => {
        e.preventDefault()
        if (e.detail.external) window.open(e.detail.href, "_blank", "noopener,noreferrer")
        else {
          setActiveHref(e.detail.href)
          navigate(e.detail.href)
        }
      }}
      activeHref={activeHref}
      items={[
        {
          type: "section",
          text: `${thunderYear} Dashboard`,
          items: [
            {
              type: "link",
              text: "Dashboard (Home)",
              href: `${routes.home}`,
            },
          ],
        },
        {
          type: "section",
          text: "Opportunities",
          items: [
            {
              type: "link",
              text: "My Opportunities",
              href: `${routes.opportunitiesList}`,
            },
            {
              type: "link",
              text: "Customer Opportunities",
              href: `${routes.customerOpportunitiesList}`,
            },
            {
              type: "link",
              text: "Search",
              href: `${routes.opportunitySearch}`,
            },
            {
              type: "link",
              text: "Create",
              href: `${routes.opportunitiesCreate}`,
            },
          ],
        },
        {
          type: "section",
          text: "Tasks (Activities)",
          items: [
            {
              type: "link",
              text: "My Tasks",
              href: `${routes.tasksList}`,
            },
          ],
        },
        { type: "divider" },
        {
          type: "link",
          text: "Explore your Partner Impact",
          href: "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/a2deaf8b-30d8-424b-b410-4ab38ec4660a?directory_alias=amazonbi&ignore=true",
          external: true,
        },
      ]}
    />
  )
}

export default SideNavigationBar
