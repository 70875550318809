import { PropertyFilterOperator, PropertyFilterToken, useCollection } from "@amzn/awsui-collection-hooks"
import {
  Box,
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  Calendar,
  CollectionPreferences,
  CollectionPreferencesProps,
  DateInput,
  FormField,
  Header,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Table,
} from "@amzn/awsui-components-react"
import NewGoalModal from "components/NewGoalModal"
import { useOpportunityTaskContext } from "context/OpportunityContext"
import { Opportunity, OPPORTUNITY_STAGE } from "models/opportunity"
import EmptyState from "pmsa-polaris/components/EmptyState"
import NavigationButton from "pmsa-polaris/components/NavigationButton"
import config from "pmsa-polaris/config"
import useFlashbar, { FlashMessage } from "pmsa-polaris/hooks/useFlashbar"
import usePersistentState from "pmsa-polaris/hooks/usePersistentState"
import useQueryString from "pmsa-polaris/hooks/useQueryString"
import { paginationLabels } from "pmsa-polaris/utils"
import qs from "qs"
import { useEffect, useState } from "react"
import routes, { routeParams } from "routes"

import { DEFAULT_COLUMN_IDS } from "./columnsNames"
import { COLUMN_DEFINITIONS, PAGE_SIZE_OPTIONS, VISIBLE_CONTENT_OPTIONS } from "./tableConfig"

const { sfdcBaseUrl } = config

const OpportunitiesTable = () => {
  const queryParams = useQueryString()

  const { filteringParams, ...queryParamsObject } = queryParams

  // context providers
  const { opportunityList, getOpportunityList, updateOpportunity } = useOpportunityTaskContext()

  // manual loading
  const [manualLoading, setManualLoading] = useState(false)

  // flash messages
  const setFlashMessages = useFlashbar()

  // error message
  const [getErrorMessage, setGetErrorMessage] = useState("Failed to load opportunities")

  useEffect(() => {
    if (opportunityList.error && opportunityList.error.response?.data?.errorCode == "USER_NOT_FOUND") {
      opportunityList.error.response?.data?.message && setGetErrorMessage(opportunityList.error.response.data.message)
    }
  }, [opportunityList.error])

  // goals modal
  const [modalVisible, setModalVisible] = useState(false)

  // table preferences
  const [preferences, setPreferences] = usePersistentState<CollectionPreferencesProps.Preferences>("thunder.opportunityTablePreferences", {
    pageSize: 10,
    visibleContent: DEFAULT_COLUMN_IDS,
    wrapLines: false,
  })

  // actions button & handler
  const actionsHandler = (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    switch (event.detail.id) {
      case "view-in-sfdc": {
        selectedItems?.forEach((item) => {
          window.open(`${sfdcBaseUrl}/${item.id}`)
        })
        break
      }
      case "view-details": {
        selectedItems?.forEach((item) => {
          window.open(routeParams.opportunitiesDetails({ id: item.id }))
        })
        break
      }
      case "edit": {
        selectedItems?.forEach((item) => {
          window.open(routeParams.opportunitiesEdit({ id: item.id }))
        })
        break
      }
      case "ext30": {
        opportunityQuickExtend(30)
        break
      }
      case "ext60": {
        opportunityQuickExtend(60)
        break
      }
      case "ext90": {
        opportunityQuickExtend(90)
        break
      }
      default: {
        break
      }
    }
  }

  const opportunityQuickExtend = async (daysToExtend: number) => {
    if (selectedItems != undefined) {
      const promises = []
      const flashMessages: FlashMessage[] = []
      const extendableHygieneIssues = ["Asleep", "Forgotten", "Stale", "Neglected"]

      for (let i = 0; i < selectedItems.length; i++) {
        if (
          selectedItems[i].hygiene.length == 0 ||
          selectedItems[i].hygiene.every((hygieneString) => extendableHygieneIssues.some((validHygiene) => hygieneString === validHygiene))
        ) {
          setManualLoading(true)

          const newOpp: Opportunity = selectedItems[i]
          const newDate = new Date(newOpp.closeDate)
          newDate.setDate(newDate.getDate() + daysToExtend)
          switch (newOpp.stageName) {
            case OPPORTUNITY_STAGE[7]:
              newOpp.stageName = OPPORTUNITY_STAGE[1]
              break
            case OPPORTUNITY_STAGE[8]:
              newOpp.stageName = OPPORTUNITY_STAGE[1]
              newOpp.closedLostCategory = ""
              newOpp.closedLostReasonNotes = ""
              break
          }
          newOpp.closeDate = newDate.toISOString().split("T")[0]
          promises.push(updateOpportunity(newOpp.id, newOpp))
        } else {
          flashMessages.push({
            type: "error",
            content: `Opportunity "${selectedItems[i].name}" has hygiene issues and cannot be updated!`,
          })
        }
      }

      await Promise.allSettled(promises).then((results) => {
        results.forEach((result, index) => {
          if (result.status == "rejected") {
            flashMessages.push({
              type: "error",
              content: `Opportunity "${JSON.parse(result.reason.response.config.data).name}" could not be updated! (${result.reason.response.data.errorCode})`,
            })

            results.splice(index, 1)
          }
        })

        if (flashMessages.length === 0) {
          flashMessages.push({
            type: "success",
            content: "All opportunities successfully updated!",
          })
        } else if (results.length > 0) {
          flashMessages.push({
            type: "success",
            content: `Remaining ${results.length === 1 ? "opportunity" : "opportunities"} successfully updated!`,
          })
        }

        if (promises.length > 0) {
          getOpportunityList()
          setManualLoading(false)
        }
      })

      setFlashMessages(flashMessages)
    }
  }

  const dateForm = ({ value, onChange }: any) => (
    <div className="date-form">
      <FormField>
        <DateInput value={value ?? ""} onChange={(e) => onChange(e.detail.value ?? "")} placeholder="YYYY/MM/DD" />
      </FormField>
      <Calendar value={value ? value.replace("/", "-") : ""} onChange={(e) => onChange(e.detail.value ?? "")} locale="en-GB" />
    </div>
  )

  // table collection
  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(opportunityList.data || [], {
    propertyFiltering: {
      empty: (
        <EmptyState
          errorText={(opportunityList.error && getErrorMessage) || undefined}
          title="No opportunities"
          subtitle="No opportunities to display."
          action={<NavigationButton href={routes.opportunitiesCreate}>Create Opportunity</NavigationButton>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can't find a match."
          action={<Button onClick={() => actions.setPropertyFiltering({ operation: "and", tokens: [] })}>Clear filter</Button>}
        />
      ),
      filteringProperties: [
        {
          propertyLabel: "Close Date",
          groupValuesLabel: "Close Date Values",
          key: "closeDate",
          operators: (["=", "!=", "<", "<=", ">", ">="] as PropertyFilterOperator[]).map((operator) => ({
            operator,
            form: dateForm,
            match: (item, token) => {
              const [d1, d2] = [Date.parse(item as string), Date.parse(token)]
              switch (operator) {
                case "=":
                  return d1 === d2
                case "!=":
                  return d1 !== d2
                case "<":
                  return d1 < d2
                case "<=":
                  return d1 <= d2
                case ">":
                  return d1 > d2
                case ">=":
                  return d1 >= d2
                default:
                  return false
              }
            },
          })),
        },
        {
          propertyLabel: "Created Date",
          groupValuesLabel: "Created Date Values",
          key: "createdDate",
          operators: (["=", "!=", "<", "<=", ">", ">="] as PropertyFilterOperator[]).map((operator) => ({
            operator,
            form: dateForm,
            match: (item, token) => {
              const [d1, d2] = [Date.parse((item as string).substring(0, 10)), Date.parse(token.substring(0, 10))]
              switch (operator) {
                case "=":
                  return d1 === d2
                case "!=":
                  return d1 !== d2
                case "<":
                  return d1 < d2
                case "<=":
                  return d1 <= d2
                case ">":
                  return d1 > d2
                case ">=":
                  return d1 >= d2
                default:
                  return false
              }
            },
          })),
        },
        {
          propertyLabel: "Partner Name",
          groupValuesLabel: "Partner Values",
          key: "accountName",
          operators: [
            { operator: "=", match: (item, token) => item === token },
            { operator: "!=", match: (item, token) => item !== token },
          ],
        },
        {
          propertyLabel: "Stage",
          groupValuesLabel: "Stage Values",
          key: "stageName",
          operators: [
            { operator: "=", match: (item, token) => item === token },
            { operator: "!=", match: (item, token) => item !== token },
          ],
        },
        {
          propertyLabel: "Activity",
          groupValuesLabel: "Activity Values",
          key: "goals",
          operators: [
            { operator: "=", match: (item, token) => item === token },
            { operator: "!=", match: (item, token) => item !== token },
          ],
        },
        {
          propertyLabel: "Opportunity ID",
          groupValuesLabel: "Opportunity ID Values",
          key: "id",
          operators: [
            { operator: "=", match: (item, token) => item === token },
            { operator: "!=", match: (item, token) => item !== token },
          ],
        },
        {
          propertyLabel: "Opportunity Name",
          groupValuesLabel: "Opportunity Name Values",
          key: "name",
          operators: [
            { operator: "=", match: (item, token) => item === token },
            { operator: "!=", match: (item, token) => item !== token },
          ],
        },
      ],
    },
    pagination: {
      pageSize: preferences.pageSize,
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: "propensityDays",
        },
        isDescending: false,
      },
    },
    selection: {},
  })

  // set initial property filter options
  useEffect(() => {
    if (!filteringParams || Object.keys(filteringParams).length === 0)
      actions.setPropertyFiltering({
        operation: "and",
        tokens: [
          {
            propertyKey: "stageName",
            operator: "!=",
            value: OPPORTUNITY_STAGE[7], // "Completed"
          },
          {
            propertyKey: "stageName",
            operator: "!=",
            value: OPPORTUNITY_STAGE[8], // "Closed Lost"
          },
        ],
      })
    else {
      const tokens: PropertyFilterToken[] = []

      Object.entries(filteringParams).forEach(([key, value]) => {
        // start and end dates for closeDate field
        if (key === "closeDateStart") {
          tokens.push({
            propertyKey: "closeDate",
            operator: ">=",
            value: value,
          })
          return
        }
        if (key === "closeDateEnd") {
          tokens.push({
            propertyKey: "closeDate",
            operator: "<=",
            value: value,
          })
          return
        }

        // if key starts with '!', operator is '!='
        // values can be comma separated list of values
        if (key.startsWith("!"))
          (value as string).split(",").forEach((v) => {
            tokens.push({
              propertyKey: key.substring(1),
              operator: "!=",
              value: v,
            })
          })
        else
          (value as string).split(",").forEach((v) => {
            tokens.push({
              propertyKey: key,
              operator: "=",
              value: v,
            })
          })
      })

      actions.setPropertyFiltering({
        operation: "and",
        tokens: tokens,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // table component
  const { selectedItems } = collectionProps
  return (
    <Box>
      <Table
        {...collectionProps}
        loading={opportunityList.loading || manualLoading}
        loadingText="Loading opportunities"
        selectionType="multi"
        stickyHeader={true}
        resizableColumns={true}
        wrapLines={preferences.wrapLines}
        visibleColumns={preferences.visibleContent}
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items}
        header={
          <Header
            variant="h2"
            counter={
              (opportunityList.data?.length &&
                (selectedItems ? `(${selectedItems.length}/${opportunityList.data?.length})` : `(${opportunityList.data?.length})`)) ||
              ""
            }
            actions={
              <SpaceBetween direction="horizontal" size="s">
                <ButtonDropdown
                  items={[
                    {
                      id: "view-in-sfdc",
                      text: "View in SFDC",
                      disabled: selectedItems?.length == 0,
                      external: true,
                    },
                    {
                      id: "view-details",
                      text: "View Details",
                      disabled: selectedItems?.length == 0,
                    },
                    {
                      id: "edit",
                      text: "Edit",
                      disabled: selectedItems?.length == 0,
                    },
                    {
                      id: "new-copy",
                      text: "New Copy",
                      disabled: selectedItems?.length != 1,
                      href: `${routeParams.opportunitiesCreate()}?${qs.stringify(selectedItems && selectedItems[0])}`,
                    },
                    {
                      id: "extend-close-date",
                      text: "Extend Close Date",
                      disabled: selectedItems?.length == 0,
                      items: [
                        {
                          id: "ext30",
                          text: "30 days",
                        },
                        {
                          id: "ext60",
                          text: "60 days",
                        },
                        {
                          id: "ext90",
                          text: "90 days",
                        },
                      ],
                    },
                  ]}
                  expandableGroups
                  onItemClick={(event) => actionsHandler(event)}
                >
                  Actions
                </ButtonDropdown>
                <NavigationButton variant="primary" href={routes.opportunitiesCreate}>
                  Create Opportunity
                </NavigationButton>
                <Button
                  variant="primary"
                  disabled={selectedItems?.length != 1}
                  onClick={() => {
                    setModalVisible(true)
                  }}
                >
                  Attach Activity
                </Button>
                <Button
                  iconName="refresh"
                  variant="icon"
                  onClick={(e) => {
                    e.preventDefault()
                    getOpportunityList()
                  }}
                />
              </SpaceBetween>
            }
          >
            Opportunities (Outcomes)
          </Header>
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            i18nStrings={{
              applyActionText: "Apply",
              clearFiltersText: "Clear",
              groupPropertiesText: "Properties",
              groupValuesText: "Values",
              operationAndText: "And",
              operationOrText: "Or",
              operatorsText: "Operators",
              operatorText: "Operator",
              propertyText: "Property",
              valueText: "Value",
            }}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: "Page size",
              options: PAGE_SIZE_OPTIONS,
            }}
            wrapLinesPreference={{
              label: "Wrap lines",
              description: "Check to see all the text and wrap the lines",
            }}
            visibleContentPreference={{
              title: "Select visible columns",
              options: VISIBLE_CONTENT_OPTIONS,
            }}
          />
        }
      />
      <NewGoalModal
        visible={modalVisible}
        setVisible={setModalVisible}
        relatedItem={selectedItems?.length == 1 ? selectedItems[0] : ({} as Readonly<Opportunity>)}
        relatedType="opportunity"
      />
    </Box>
  )
}

export default OpportunitiesTable
